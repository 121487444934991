.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-header {
  height: 15vmin;
  pointer-events: none;
}

.App-header {
  background-color: #2b2d42;
  min-height: 100vh; 
  display: flex; 
  flex-direction: column;
  align-items: center;
 /* justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: white;
  font-style: Noto Serif Ethiopic Condensed;
}
.App-footer {
  background-color: #BEA57D;
  text-align: center;
  position: relative;
  bottom: 0;
  width:100%;
  padding-top: 20px;
  padding-bottom: 25%;
 }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-navbar {
  background-color: #dd1c1a !important; /* Tomato color */
  
}

.custom-navbar .nav-link {
  color: #BEA57D !important; /* White text */
}

.custom-navbar .navbar-brand {
  color: #BEA57D !important; /* Light goldenrod yellow brand */
}

.home {
  padding-top: 20px;
  padding-bottom: 100px;
}

.gallery-photos {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex; 
  flex-direction: column;
  align-items: center;
}

.results-photos {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
